@use '@/styles/utils/mixins.scss' as *;

.ctaBannerWrapper {
  margin-top: 7.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6.75rem;

  @include tab() {
    margin-top: 5.125rem;
  }

  .ctaBannerContent {
    display: flex;
    justify-content: space-between;
    gap: 2.625rem;
    align-items: flex-end;
    width: 100%;

    @include tab() {
      flex-direction: column;
      align-items: center;
    }
  }

  .ctaBannerTitle {
    display: flex;
    flex-direction: column;

    span {
      color: $dark;
    }

    @include tab() {
      text-align: center;
      align-items: center;
    }
  }

  .description {
    text-align: left;
    width: 100%;
    max-width: 25.75rem;

    @include tab() {
      text-align: center;
    }
    a {
      color: $primary;
      &:hover,
      &:focus,
      &:active {
        color: $primaryAlt;
      }
    }
  }

  .bannerCard {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.875rem;
    background-color: $white;
    border-radius: 1.25rem;
    padding: 2.5rem 1.875rem;
    border: 1px solid $white;
    -webkit-box-shadow: 0px 0rem 0rem 0px rgba($black, 0);
    -moz-box-shadow: 0px 0rem 0rem 0px rgba($black, 0);
    box-shadow: 0px 0rem 0rem 0px rgba($black, 0);
    transition: all 0.2s ease-in-out;

    &:hover,
    &:focus,
    &:active {
      border-color: $primaryAlt;
      -webkit-box-shadow: 0px 0.375rem 1rem 0px rgba($primaryAlt, 0.35);
      -moz-box-shadow: 0px 0.375rem 1rem 0px rgba($primaryAlt, 0.35);
      box-shadow: 0px 0.375rem 1rem 0px rgba($primaryAlt, 0.35);
    }

    @include mob() {
      grid-template-columns: repeat(1, 1fr);
      gap: 1.25rem;
      padding: 1.875rem;
      margin: 0 -1.875rem;
      width: calc(100% + 3.75rem);
    }

    .bannerCardContent {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .metaData {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      gap: 0.5rem;
      list-style: none;
      padding: 0;
      margin: 0;
      font-size: 1rem;
      color: $neutral07;

      li {
        display: flex;
        gap: 0.5rem;
        align-items: baseline;

        &:not(:last-child)::after {
          content: '|';
        }
      }
    }
  }

  .bannerImageWrapper {
    position: relative;
    overflow: hidden;
    border-radius: 1.25rem;
    width: 100%;
  }

  .bannerImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
